<template>
    <div class="admin-settings-trnaslations">
        <b-card class="cms-pages-admin" :title="$t('Coupons')">
            <b-row>
                <b-col cols="12">
                    <good-data-table ref="couponTable" :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
                        :api-endpoint="'/subscription/coupons-paginate'" :button-label="$t('Generate Coupon(s)')"
                        @add-new-info="toggelModal = true" />
                </b-col>
            </b-row>
        </b-card>
        <b-modal v-model="toggelModal" :no-close-on-backdrop="true" :title="$t('Generate Coupon')"
            :ok-title="$t('Generate')" ok-only hide-footer @ok="submitForm">
            <b-row>
                <b-col cols="12">
                    <validation-observer ref="couponForm">
                        <b-form @submit.prevent="submitForm">
                            <b-row class="d-flex justify-content-center">
                                <b-col cols="12">
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Partner Name')" label-for="partner_name">
                                                <validation-provider #default="{ errors }" :name="$t('Partner Name')"
                                                    rules="required">
                                                    <b-form-input id="partner_name" v-model="formData.partner_name"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('Partner Name')" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Partner Email')" label-for="partner_email">
                                                <validation-provider #default="{ errors }" :name="$t('Partner Email')"
                                                    rules="required">
                                                    <b-form-input id="partner_email" v-model="formData.partner_email"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('Partner Email')" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Package')" label-for="sp_uuid">
                                                <validation-provider #default="{ errors }" :name="$t('Package')"
                                                    rules="required">
                                                    <b-form-select id="package" v-model="formData.sp_uuid"
                                                        :options="packageOpts" :state="errors.length > 0 ? false : null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('No Of Coupons')" label-for="no_coupon">
                                                <validation-provider #default="{ errors }" :name="$t('No Of Coupons')"
                                                    rules="required|integer">
                                                    <b-form-input id="no_coupon" v-model="formData.no_coupon"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('No Of Coupons')" type="number" :min="1" :max="100"
                                                        :step="1" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Discount (%)')" label-for="discount">
                                                <validation-provider #default="{ errors }" :name="$t('discount')"
                                                    rules="required">
                                                    <b-form-input 
                                                        id="discount" 
                                                        v-model="formData.discount"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('Discount')" 
                                                        type="number" 
                                                        :step="0.1"
                                                        :min="1"
                                                        :max="100"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group :label="$t('Coupon Description')" label-for="description">
                                                <validation-provider #default="{ errors }" :name="$t('Coupon Description')">
                                                    <b-form-textarea id="description" v-model="formData.description"
                                                        :state="errors.length > 0 ? false : null"
                                                        :placeholder="$t('Coupon Description')" rows="3" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <b-button variant="success" type="submit" class="float-right">
                                                {{ $t('Submit') }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
    
<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    required, integer
} from '@validations'
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        GoodDataTable
    },
    data: () => ({
        toggelModal: false,
        formData: {
            partner_name: '',
            partner_email: '',
            description: '',
            sp_uuid: '',
            no_coupon: 10,
            discount: 100,
        },
        required, integer
    }),
    computed: {
        ...mapGetters('subscriptionPlan', [
            'isSubscriptionLoaded',
            'allSubscriptions',
            'getSubscription',
        ]),
        packageOpts: {
            get() {
                return this.allSubscriptions.filter(i => i.package_amount > 0).map(i => ({
                    text: `${i.package_for} - ${i.tenure_type} - ${i.package_name} ($${i.package_amount})`,
                    value: i.uuid
                }))
            }
        },
        fields: {
            get() {
                return [
                    {
                        field: 'partner_name',
                        label: 'Partner Name',
                    },
                    {
                        field: 'product',
                        label: 'Product Name',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => {
                            const sp = this.getSubscription(props.sp_uuid);
                            if(isEmpty(sp)) return null;
                            return sp.package_for;
                        },
                    },
                    {
                        field: 'pricing',
                        label: 'Pricing',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => {
                            const sp = this.getSubscription(props.sp_uuid);
                            if(isEmpty(sp)) return null;
                            return `$${sp.package_amount}`
                        },
                    },
                    {
                        field: 'is_used',
                        label: 'Used',
                        useResolver: true,
                        useHtml: true,
                        renderer: props => isEmpty(props.used_by) ? 'No' : 'Yes'
                    },
                    {
                        field: 'used_by',
                        label: 'Used By',
                    },
                    {
                        field: 'coupon_code',
                        label: 'Code',
                    },
                    {
                        field: 'discount',
                        label: 'Discount',
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        sortable: false,
                        type: 'dropdown',
                        actions: [
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('delete'),
                                text: 'Delete',
                                action: props => {
                                    this.$swal({
                                        title: this.$t('You want to delete this coupon?'),
                                        iconHtml: this.$helpers.swalIcon('alert.png'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('Yes'),
                                        cancelButtonText: this.$t('No'),
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger ml-1',
                                            icon: 'border-0',
                                        },
                                        buttonsStyling: false,
                                    }).then(result => {
                                        if (result.value) {
                                            this.deleteItem(props)
                                        }
                                    })
                                },
                            },
                        ],
                    },
                ]
            }
        }
    },
    mounted() {
        if (!this.isSubscriptionLoaded) {
            this.getSubscriptionPackages()
        }
    },
    methods: {
        ...mapActions('coupon', [
            'createCoupon',
            'deleteCoupon',
        ]),
        ...mapActions('subscriptionPlan', [
            'getSubscriptionPackages',
        ]),
        async deleteItem(i){
            await this.deleteCoupon(i.uuid)
            this.$refs.couponTable.loadItems()
        },
        submitForm() {
            this.$refs.couponForm.validate().then(success => {
                if (success) {
                    showLoader()
                    const formDataObj = new FormData
                    Object.keys(this.formData).forEach(i => {
                        formDataObj.append(i, this.formData[i])
                    })
                    this.createCoupon(formDataObj).then(({ data }) => {
                        if (data) {
                            if (data.statusCode === '200') {
                                showSuccessNotification(this, "Coupon Generated successfully")
                                this.$refs.couponTable.loadItems()
                            }
                        }
                    }).catch(error => {
                        showErrorNotification(this, error)
                    })
                    hideLoader()
                    this.toggelModal = false
                }
            });
        }
    }
}
</script>
    